@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
:export {
  qrbg: #f6f6f6;
  qrcolor: #00404f;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 55%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading.component-loader {
  margin-top: -60px;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7961f9;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7961f9;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .loading .effects {
  transition: all 0.3s ease;
}

.fallback-logo {
  position: absolute;
  left: calc(50% - 75px);
  top: 40%;
}

.fallback-spinner {
  border: 2px solid white;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
.keyboard-col {
  padding: 0 6vw;
}
.keyboard-col .keyboard-wrapper {
  width: 100%;
  background-color: #f6f6f6;
  border: 0.2vw solid rgba(0, 64, 79, 0.1);
  border-radius: 0.8vw;
}
.keyboard-col .keyboard-wrapper .col {
  padding: 0 !important;
}
.keyboard-col .keyboard-wrapper .input-wrapper {
  width: 90%;
  margin: 0 auto;
  color: #00404f;
}
.keyboard-col .keyboard-wrapper .input-wrapper .pin-key {
  min-height: 10vh;
  max-height: 10vh;
  padding: 0;
  position: relative;
}
.keyboard-col .keyboard-wrapper .input-wrapper .pin-key .pin-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  font-size: 2.5vw;
}
.keyboard-col .keyboard-wrapper .input-wrapper .pin-key .float {
  background-color: #00404f;
  width: 35%;
  position: absolute;
  bottom: 1.5vh;
  height: 0.2vh;
  left: 50%;
  transform: translate(-50%, 0%);
}
.keyboard-col .keyboard-wrapper .keys-row {
  margin: 4vh 0 3vh 0;
}
.keyboard-col .keyboard-wrapper .keys-row .col {
  color: #00404f;
  margin-bottom: 0.4vw;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row {
  margin: 0;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb {
  display: flex;
  align-items: center;
  justify-content: center;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key {
  width: 70%;
  height: 80%;
  margin: 2.5vh 0;
  background-color: transparent;
  border-radius: 0.7vw;
  transition: all 0.1s ease-in-out;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key:active {
  background-color: #e2e2e2;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key .key-text {
  margin: 0;
  font-size: 1.5vw;
  font-weight: bold;
  color: #00404f;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key .delete-icon {
  height: 1.4vw;
  width: 1.4vw;
}
.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key .delete-icon * {
  stroke: #00404f;
}
.keyboard-col .keyboard-wrapper .confirm-button-row {
  margin: 5vh 0 4vh 0;
  height: 9vh;
}
.keyboard-col .keyboard-wrapper .confirm-button-row button {
  border-radius: 0.7vw;
  color: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 100%;
  outline: none;
  border: none;
  background-color: #0282C3;
  font-size: 2.2vw;
}

.Toastify__toast-container .Toastify__toast {
  box-shadow: none !important;
  border-radius: 0.4vw;
}
.Toastify__toast-container .Toastify__toast.Toastify__toast--success {
  background: white !important;
  border: 2px solid #00404f;
}
.Toastify__toast-container .Toastify__toast.Toastify__toast--error {
  background: white !important;
  border: 2px solid red;
}
.Toastify__toast-container .Toastify__toast .Toastify__toast-body {
  color: #00404f !important;
}
.Toastify__toast-container .Toastify__toast .Toastify__close-button {
  color: #00404f;
}
.Toastify__toast-container .Toastify__toast .Toastify__progress-bar {
  background-color: #00404f;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
}

#root {
  padding: 0;
  margin: 0;
  max-height: 100vh;
  min-height: 100vh;
}

p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #626262;
  user-select: none;
}

h4 {
  font-size: 1.32rem;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
}

.office-container {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  position: relative;
}
.office-container .logo-row {
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 5vh auto;
}
.office-container .logo-row svg {
  padding: 0;
  margin: 0;
  width: 10vw;
}
.office-container .logo-row svg * {
  fill: #00404f;
}
.office-container .lang-row {
  position: absolute;
  top: 3vh;
  right: 4vw;
  width: auto;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}
.office-container .lang-row button {
  border-radius: 0.4vw;
  color: #00404f;
  outline: none;
  border: 0.2vw solid #00404f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: 4vw;
  font-size: 2vw;
}
.office-container .lang-row button:first-of-type {
  margin-right: 2vw;
}
.office-container .lang-row button.active {
  border: none;
  background-color: #0282C3;
}
.office-container .pin-submit-office {
  margin: 0;
  height: 100%;
  width: 100%;
}
.office-container .pin-submit-office .help-section {
  padding: 0;
}
.office-container .pin-submit-office .help-section .help-container {
  width: 100%;
  height: 100%;
}
.office-container .pin-submit-office .help-section .help-container .section-title {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  margin: 24vh 0 5vh 0;
  text-align: center;
}
.office-container .pin-submit-office .help-section .help-container .section-title h2 {
  font-size: 3.5vw;
  color: #0282C3;
}
.office-container .pin-submit-office .help-section .help-container .qr-div {
  margin: auto;
  width: fit-content;
  border: 0.2vw solid rgba(128, 128, 128, 0.3);
  border-radius: 0.7vw;
}
.office-container .pin-submit-office .help-section .help-container .qr-div canvas {
  padding: 0;
  border-radius: 0.7vw;
}
.office-container .pin-submit-office .help-section .help-container .subtitle-row {
  margin: 7vh 0 0vh 0;
}
.office-container .pin-submit-office .help-section .help-container .subtitle-row p {
  font-size: 1.4vw;
  padding: 0;
  margin: auto;
  width: 50%;
  text-align: center;
}
.office-container .pin-submit-office .help-section .help-container .subtitle-row p span {
  color: #0282C3;
  font-size: 1.6vw;
}
.logo-img{
  width: 100%;
}
.modal-text{
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-weight: 800;
}
.modal-door-wrapper{
  margin: auto;
  margin-top: 5vh;
  border: 4px solid #0282C3;
  border-radius: 10px;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-door{
  font-size: 6rem;
  font-weight: 800;
  color: #0282C3;
}
.modal-body{
  padding: 5%;
}
.modal-btn{
  font-size: 2.5rem;
  width: 15vw;
  padding: 1vw;
}
